import { defineStore } from 'pinia'
import { computed, reactive, ref, Ref } from 'vue'

import userApi from '@/api/user'
import { fetchWrapper } from '@/api/fetchApi'

import type { TUser, TUserPaginated } from '@/types/User'

export const useUserStore = defineStore('user', () => {
  // state
  const user: Ref<TUser | null> = ref(null)
  const pagination: TUserPaginated = reactive({
    page_number: 1,
    elements_on_page: 10,
    total: null,
    number_of_pages: undefined,
    data: null
  })

  // getters
  const getName = computed(() => {
    if (!user.value?.last_name) return user.value?.username || ''
    return `${user.value?.first_name} ${user.value?.last_name}`
  })

  //actions
  const aboutMe = async () => {
    try {
      const userInfo = await userApi.userInfo()

      user.value = userInfo
    } catch {
      throw new Error()
    }
  }

  const fetchPagination = async () => {
    const res = await fetchWrapper.post(
      `/users/?order_by=territory_id&page_number=${pagination.page_number}&elements_on_page=10`,
      {}
    )
    Object.assign(pagination, res)
  }

  return {
    // state
    user,
    pagination,

    // getters
    getName,

    // action
    aboutMe,
    fetchPagination
  }
})
