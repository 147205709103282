import { fetchWrapper } from './fetchApi'
import { TUser } from '@/types/User'

export default {
  async userInfo() {
    try {
      return (await fetchWrapper.get('/users/me')) as TUser
    } catch (e) {
      localStorage.removeItem('tokenData')
      throw new Error(`Не удалось получить информацию о пользователе: ${e.message}`)
    }
  },
  async editUser(newUser: TUser) {
    try {
      await fetchWrapper.patch(`/users/${newUser.id}`, newUser)
    } catch (e) {
      throw new Error(`Не удалось изменить информацию о пользователе: ${e.message}`)
    }
  }
}
