import { createWebHistory, createRouter } from 'vue-router'

import useRouter from '@/composition/useRouter'
import { routerScroll } from '@/utils/scroll/scroll'

import routes from '@/assets/data/routes'

const { appGlobalRedirect } = useRouter()

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: routerScroll
})

router.beforeEach(appGlobalRedirect)
router.afterEach((to) => {
  if (to.query.token) {
    const url = new URL(window.location.href)
    window.history.pushState({}, '', url.pathname)
  }
})

export default router
