import { computed } from 'vue'
import { RouteLocation } from 'vue-router'

import { useUserStore } from '@/stores/user'
import { useAuthStore } from '@/stores/auth'
import { getHostName } from '@/utils/url'

const publicPages = ['/login']
const { VITE_GIS_ROOT } = import.meta.env

async function adminRedirect() {
  const userStore = useUserStore()

  const user = computed(() => userStore.user)

  const isAdmin = user.value?.is_admin

  if (!isAdmin) {
    return {
      path: '/'
    }
  }
}

async function appGlobalRedirect(to: RouteLocation) {
  const userStore = useUserStore()
  const authStore = useAuthStore()

  const authRequired = !publicPages.includes(to.path)
  if (VITE_GIS_ROOT) {
    const url = new URL(window.location.href)
    const searchParams = url.searchParams
    const access_token = searchParams.get('token')

    if (access_token) {
      authStore._setToken({
        access_token
      })
    }
  }
  const notAuthorithed = authRequired && !authStore.token

  if (notAuthorithed) {
    return {
      path: '/login'
    }
  }

  const isAdminRedirect = authRequired && authStore.token
  if (!isAdminRedirect) return

  try {
    await userStore.aboutMe()

    const isAdminPage = to.path === '/admin'
    if (isAdminPage) return await adminRedirect()
  } catch {
    const APP_URL = getHostName()

    if (VITE_GIS_ROOT) {
      window.location.href = `${VITE_GIS_ROOT}/login?redirect=${APP_URL}`
    }
  }
}

export default function () {
  return {
    appGlobalRedirect
  }
}
