import { computed, reactive } from 'vue'
import { defineStore } from 'pinia'
import { useRoute } from 'vue-router'

import { EgrnLayers, GeometryMapLayers } from '@/utils/lists/lists'
import { useRoadStore } from '@/stores/road/road'
import layerNames from '@/utils/map/layerNames'

import type { GeometryFeatureInfo, MapFeatureRendered, MapRenderParams } from '@/types/map/Map'
import type { GeometryNav } from '@/types/ui/Nav'
import type { GeometryState } from '@/types/Geometry'
import type { Map } from 'maplibre-gl'
import type { Geometry } from '@turf/turf'

const { layerName } = layerNames()

export const useGeometryStore = defineStore('geometry', () => {
  const roadStore = useRoadStore()
  const renderData = computed(() => roadStore.renderData)

  const state: GeometryState = reactive({
    map: undefined,
    nav: [
      {
        name: 'Исследование керна',
        id: 'kern',
        active: true,
        disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.KERN))
      },
      {
        name: 'Дорожные знаки',
        id: GeometryMapLayers.ROAD_SIGN,
        active: true,
        disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_SIGN))
      },
      {
        name: 'Дорожные объекты',
        id: GeometryMapLayers.ROAD_ELEMENTS,
        active: true,
        opened: false,
        disabled: false,
        children: [
          {
            name: 'Покрытие дороги',
            id: GeometryMapLayers.ROAD_WAY,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            opened: false,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_WAY))
          },
          {
            name: 'Ось дороги',
            id: GeometryMapLayers.ROAD_AXIS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_AXIS))
          },
          {
            name: 'Разметка',
            id: GeometryMapLayers.ROAD_MARKS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_MARKS)
            ),
            parentId: GeometryMapLayers.ROAD_ELEMENTS
          },
          {
            name: 'Бордюры',
            id: GeometryMapLayers.ROAD_BORDERS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_BORDERS)
            )
          },
          {
            name: 'Тротуары',
            id: GeometryMapLayers.ROAD_SIDE_WALKS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_SIDE_WALKS)
            )
          },
          {
            name: 'Искуcственные неровности',
            id: GeometryMapLayers.ROAD_BUMPS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_BUMPS))
          },
          {
            name: 'Мосты',
            id: GeometryMapLayers.ROAD_BRIDGES,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_BRIDGES)
            )
          },
          {
            name: 'Пешеходные переходы',
            id: GeometryMapLayers.ROAD_CROSSWALKS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_CROSSWALKS)
            )
          },
          {
            name: 'Климатические зоны',
            id: GeometryMapLayers.ROAD_CLIMATIC,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_CLIMATIC)
            )
          },
          {
            name: 'Кривые',
            id: GeometryMapLayers.ROAD_CURVES,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_CURVES)
            )
          },
          {
            name: 'Весовой контроль',
            id: GeometryMapLayers.ROAD_WEIGHT_CONTROL,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_WEIGHT_CONTROL)
            )
          },
          {
            name: 'Сигнальные столбики',
            id: GeometryMapLayers.ROAD_GUIDE_STAKE_SEGMENTS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            opened: false,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_GUIDE_STAKE_SEGMENTS)
            )
          },
          {
            name: 'Обочина',
            id: GeometryMapLayers.ROAD_EDGES,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_EDGES))
          },
          {
            name: 'Освещение',
            id: GeometryMapLayers.ROAD_LIGHT,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_LIGHT))
          },
          {
            name: 'Земельные участки',
            id: GeometryMapLayers.ROAD_LAND_LOTS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_LAND_LOTS)
            )
          },
          {
            name: 'Съезд с дороги',
            id: GeometryMapLayers.ROAD_EXIT,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.ROAD_EXIT))
          },
          {
            name: 'Эксплуатационная категория',
            id: GeometryMapLayers.EXPLUATATION_CATEGORY,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.EXPLUATATION_CATEGORY)
            )
          },
          {
            name: 'Ограждения',
            id: GeometryMapLayers.ROAD_FENCE_SEGMENTS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_FENCE_SEGMENTS)
            )
          },
          {
            name: 'Железнодорожные объекты',
            id: GeometryMapLayers.RAILWAY_CROSSING,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.RAILWAY_CROSSING)
            )
          },
          {
            name: 'Осветительные столбы',
            id: GeometryMapLayers.LIGHTING_POLES,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.LIGHTING_POLES)
            )
          },
          {
            name: 'Укрепления дороги',
            id: GeometryMapLayers.ROAD_FORTIFICATIONS,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_FORTIFICATIONS)
            )
          },
          {
            name: 'Трамвайная линия',
            id: GeometryMapLayers.TRUM_LINE,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.TRUM_LINE))
          },
          {
            name: 'Колодец ливневой канализации',
            id: GeometryMapLayers.STROM_DRAIN_WELL,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.STROM_DRAIN_WELL)
            )
          },
          {
            name: 'Водоотведение',
            id: GeometryMapLayers.WATER_DISPOSAL,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.WATER_DISPOSAL)
            )
          },
          {
            name: 'Разделительная полоса',
            id: GeometryMapLayers.SEPARATE_LINE,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.SEPARATE_LINE)
            )
          }
        ]
      },
      {
        name: 'Придорожная полоса',
        id: GeometryMapLayers.ROADSIDE_STRIP,
        active: true,
        opened: false,
        disabled: false,
        children: [
          {
            name: 'Здания',
            id: GeometryMapLayers.ROAD_REALTY,
            parentId: GeometryMapLayers.ROADSIDE_STRIP,
            active: true,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_REALTY)
            )
          },
          {
            name: 'Озеленение',
            id: GeometryMapLayers.ROAD_LANDSCAPING,
            active: true,
            parentId: GeometryMapLayers.ROADSIDE_STRIP,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ROAD_LANDSCAPING)
            )
          },
          {
            name: 'Прилегающие угодья',
            id: GeometryMapLayers.ADJACENT_LANDS,
            active: true,
            parentId: GeometryMapLayers.ROADSIDE_STRIP,
            disabled: computed(() =>
              defineDisabled(renderData.value, GeometryMapLayers.ADJACENT_LANDS)
            )
          },
          {
            name: 'Остановка общественного транспорта',
            id: GeometryMapLayers.BUS_STOP,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.BUS_STOP))
          },
          {
            name: 'Площадки',
            id: GeometryMapLayers.SQUARE,
            parentId: GeometryMapLayers.ROAD_ELEMENTS,
            active: true,
            disabled: computed(() => defineDisabled(renderData.value, GeometryMapLayers.SQUARE))
          }
        ]
      }
    ],
    featureInfo: {
      content: '',
      isOpen: false,
      activeFeature: undefined,
      isLoading: false,
      timeout: 0
    },
    activeLayers: [
      GeometryMapLayers.ROAD_LAND_LOTS,
      GeometryMapLayers.ROAD_WAY,
      GeometryMapLayers.ROAD_MARKS,
      GeometryMapLayers.ROAD_SIDE_WALKS,
      GeometryMapLayers.ROAD_REALTY,
      GeometryMapLayers.ROAD_LIGHT,
      GeometryMapLayers.ROAD_EDGES,
      GeometryMapLayers.ROAD_BRIDGES,
      GeometryMapLayers.ROAD_CURVES,
      GeometryMapLayers.ROAD_CLIMATIC,
      GeometryMapLayers.ROAD_BORDERS,
      GeometryMapLayers.ROAD_SEMAPHORES,
      GeometryMapLayers.ROAD_WEIGHT_CONTROL,
      GeometryMapLayers.ROAD_EXIT,
      GeometryMapLayers.ROAD_AXIS,
      GeometryMapLayers.ROAD_SIGN,
      GeometryMapLayers.ROAD_BUMPS,
      GeometryMapLayers.ROAD_CROSSWALKS,
      GeometryMapLayers.ROAD_GUIDE_STAKE_SEGMENTS,
      GeometryMapLayers.ROAD_CATEGORY_SEGMENTS,
      GeometryMapLayers.ROAD_FENCE_SEGMENTS,
      GeometryMapLayers.RAILWAY_CROSSING,
      GeometryMapLayers.EXPLUATATION_CATEGORY,
      GeometryMapLayers.LIGHTING_POLES,
      GeometryMapLayers.ROAD_FORTIFICATIONS,
      GeometryMapLayers.KERN,
      GeometryMapLayers.ROAD_LANDSCAPING,
      GeometryMapLayers.ADJACENT_LANDS,
      GeometryMapLayers.TRUM_LINE,
      GeometryMapLayers.STROM_DRAIN_WELL,
      GeometryMapLayers.WATER_DISPOSAL,
      GeometryMapLayers.BUS_STOP,
      GeometryMapLayers.ROAD_MARKS,
      GeometryMapLayers.SQUARE,
      GeometryMapLayers.SEPARATE_LINE
    ],
    interactiveLayers: [
      EgrnLayers.LANDS,
      EgrnLayers.BUILDS,
      EgrnLayers.CONSTRUCTIONS,
      EgrnLayers.CONSTRUCTIONS_LINES,
      EgrnLayers.CONSTRUCTIONS_CURVES
    ],
    selectedFeature: undefined
  })

  const route = useRoute()

  const getNavData = computed(() => state.nav)
  const map = computed(() => state.map)
  const getFeatureInfo = computed(() => state.featureInfo)
  const popupContent = computed(() => state.featureInfo.content)
  const isOpened = computed(() => {
    return getNavData.value.some((el) => el.opened)
  })
  const interactiveLayers = computed(() => {
    if (route?.query?.id === '11') {
      const index = state.activeLayers.findIndex((el: string) => el === 'kern')

      if (index > -1) state.activeLayers.push('kern')
    } else {
      state.activeLayers.forEach((item, index) => {
        if (item === 'kern') state.activeLayers.splice(index, 1)
      })
    }

    return layerName(state.activeLayers).concat(state.interactiveLayers)
  })
  const selectedFeature = computed(() => state.selectedFeature)

  function openInfoMenu(content: GeometryFeatureInfo, map: Map) {
    content.list = content.list.filter((el) => el.value)

    state.featureInfo.content = content
    state.featureInfo.isOpen = true
    state.map = map
  }

  function setMap(map: Map) {
    state.map = map
  }

  function closeInfoMenu() {
    state.featureInfo.isOpen = false
  }

  function toggleNested(item: GeometryNav) {
    item.opened = !item.opened
  }

  function toggleActive(item: GeometryNav, checked?: boolean) {
    item.active = checked ?? !item.active
  }

  function resetActive(data: GeometryNav[]) {
    data.forEach((item) => {
      item.active = true

      if (item.children) {
        item.opened = false
        resetActive(item.children)
      }
    })
  }

  function togglePreloader() {
    state.featureInfo.isLoading = !state.featureInfo.isLoading
  }

  function defineDisabled(renderData: MapRenderParams[], name: string) {
    const current = renderData.find(
      (el) => el.params.name === name || el.params.name.startsWith(name)
    )

    const isEmpty = !current?.data?.features?.length

    const notCoordinates = !current?.data?.features?.some((el) => {
      const isSimple = !el.geometry?.geometries?.length

      return isSimple
        ? el.geometry?.coordinates?.length
        : el.geometry?.geometries?.some((item: Geometry) => item.coordinates?.length)
    })

    return isEmpty || notCoordinates
  }

  function updateSelectedFeature(feature?: MapFeatureRendered) {
    state.selectedFeature = feature
  }

  return {
    getNavData,
    getFeatureInfo,
    popupContent,
    map,
    interactiveLayers,
    selectedFeature,

    openInfoMenu,
    closeInfoMenu,

    toggleNested,
    toggleActive,
    togglePreloader,
    resetActive,
    updateSelectedFeature,

    isOpened,
    setMap
  }
})
