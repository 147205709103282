<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { useAuthStore } from '@/stores'
import { getHostName } from '@/utils/url'
import AppTitle from '@/components/layouts/header/AppTitle.vue'
import AppBackground from '@/components/layouts/background/AppBackground.vue'
import WindowBorder from '@/components/ui/WindowBorder.vue'
import AuthForm from '@/components/forms/AuthForm.vue'
import InfiniteLights from '@/components/other/InfiniteLights.vue'
import TechSupport from '@/components/layouts/login/TechSupport.vue'

const { VITE_GIS_ROOT } = import.meta.env

const authStore = useAuthStore()

onBeforeMount(async () => {
  if (!authStore.token && VITE_GIS_ROOT) {
    const APP_URL = getHostName()
    window.location.href = `${VITE_GIS_ROOT}/login?redirect=${APP_URL}`
  }
})
</script>

<template>
  <div class="auth">
    <infinite-lights />
    <app-background />
    <div class="auth__container">
      <app-title class="auth__title" :logo-width="108" :logo-height="55" />
      <window-border>
        <auth-form />
      </window-border>
    </div>
    <TechSupport />
  </div>
</template>

<style lang="scss">
.auth {
  position: relative;
  height: 100vh;
  background-color: white;

  &__title {
    position: absolute;
    top: 4rem;
    left: 4rem;

    .appTitle__title {
      font-size: 1.15em;
    }
  }

  &__container {
    display: grid;
    position: relative;
    z-index: 10;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
  }
}
</style>
